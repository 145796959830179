<template>
  <div class="auth">
    <figure class="auth-bg">
      <figcaption>Health Transformation driven by Future Population Demand</figcaption>
      <img src="@/assets/img/KSAPlatformPic.jpg" alt="Health System Intelligence">
    </figure>
    <div class="auth-content">
      <div class="auth-content--container">
        <img src="@/assets/img/logo-full-blue.png" alt="Health System Intelligence">
        <form class="auth-content--form form-primary" @submit.prevent="processLogin()">
          <h2 class="text-3xl mb-5">Login</h2>
          <span class="error" v-if="error">{{ error }}</span>
          <div class="mb-8 mt-6">
            <label class="mb-4" for="username">Email Address</label>
            <input class="appearance-none w-full mr-3 py-2 px-1 focus:outline-none" v-model="form.email" type="text" placeholder="email@domain.com" aria-label="Email">
          </div>
          <div class="mb-8">
            <label class="mb-4" for="password">Password</label>
            <input class="appearance-none w-full mr-3 py-2 px-1 focus:outline-none" v-model="form.password" type="password" placeholder="**********" aria-label="Password">
          </div>
          <button type="submit" class="btn btn-primary" :disabled="isProcessing">Log in</button>
          <a href="">Forgot your password?</a>
        </form>
        <span>&copy; 2024 Health System Intelligence Ltd. All Rights Reserved.</span>
        <span>hsi-ksah.com</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      error: null,
      isProcessing: false
    };
  },
  methods: {
    processLogin() {
      this.isProcessing = true

      this.$store
        .dispatch('attemptLogin', this.form)
        .then(() => {
          this.$router.push("/summary");
        })
        .catch(() => {
          this.error = 'Incorrect email or password'
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
};
</script>
