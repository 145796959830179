<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">System Population Optimization Benchmark</h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Kingdom of Saudi Arabia</span>
        </div>
      </div>
      <!-- div class="route-header--box">
        <div class="box">
          <span class="box-header">Total Population</span>
          <span class="box-label"> {{  this.population }} </span>
        </div>
      </div -->

     
    </div>

    <nav class="route-nav">
      <ul>
        <li
          @click="editSummary('Strategic')"
          :class="Summary == 'Strategic' ? 'active' : ''"
        >
          <span>Strategic Analytics Insights</span>
        </li>
        <li
          @click="editSummary('Episodes')"
          :class="Summary == 'Episodes' ? 'active' : ''"
        >
          <span>Episodes</span>
        </li>
        <li
          @click="editSummary('Assets and Infrastructure')"
          :class="Summary == 'Assets and Infrastructure' ? 'active' : ''"
        >
          <span>Assets and Infrastructure</span>
        </li>
        <li
          @click="editSummary('Workforce')"
          :class="Summary == 'Workforce' ? 'active' : ''"
        >
          <span>Workforce</span>
        </li>
        <li
          @click="editSummary('Finance')"
          :class="Summary == 'Finance' ? 'active' : ''"
        >
          <span>Finance</span>
        </li>
      </ul>
    </nav>
   

    <div v-if="stageSummary == 'metrics'">
        <div class="route-content-speciality">             
          <div class="route-content">
          <!-- 1 open -->
          <div v-if="stageFive != null">
          <table class="table-primary">
            <thead>
              <tr>
                <th class="border px-4 py-2"></th>
                <th class="border px-4 py-2">Population</th>
                <th class="border px-4 py-2">Episode Productivity</th>
                <th class="border px-4 py-2">Assets</th>
                <th class="border px-4 py-2">Workforce</th>
                <th class="border px-4 py-2">Finance Cost Budget</th>                
              </tr>
            </thead>
            <tbody>
              <tr>          
                <td class="border">
                  <div v-for="(jobPlan, index) in StageSummary" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlan, index) in StagePopulation" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanPatients, index) in StageEpisodeProductivity" :key="index">
                    <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanPatients }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanperSessions, index) in StageAssets" :key="index">
                    <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanperSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanannSessions, index) in StageWorkforce" :key="index">
                    <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanannSessions }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlanweekSessions, index) in StageFinance" :key="index">
                    <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlanweekSessions }}</span> 
                  </div>
                </td>                
              </tr>              
              <tr style="border-bottom-style: hidden; border-top-style: hidden;">
                <td class="border" colspan="2" style="border-left-style: hidden;">
                  <div>
                    <span><div v-html="message"/></span>                    
                  </div>
                </td>
                
                <td class="border" >
                  <div>
                    <span> {{ current_optimised.episode_productivity }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div>
                    <span>{{ current_optimised.assets }}</span>    
                  </div>
                </td>

                <td class="border">
                  <div>
                    <span> {{ current_optimised.workforce }}</span> 
                  </div>
                </td>

                <td class="border">
                <div>
                  <span> {{ current_optimised.finance_cost_budget }}</span> 
                </div>
                </td>                                                 
              </tr>

              <tr>
                <td class="border" colspan="6"/>                               
              </tr>
              
              <tr style="border-bottom-style: hidden; border-top-style: hidden;">
              <td class="border" colspan="2">
                  <div>% Excess</div>                    
                </td>
                
                <td class="border">
                <div>
                  <span> {{ percent_excess.episode_productivity }}</span> 
                </div>
                </td>

                <td class="border">
                  <div>
                    <span>{{ percent_excess.assets }}</span>    
                  </div>
                </td>

                <td class="border">
                <div>
                  <span> {{ percent_excess.workforce }}</span> 
                </div>
                </td>

                <td class="border">
                <div>
                  <span> {{ percent_excess.finance_cost_budget }}</span> 
                </div>
                </td>                                                 
              </tr>

              <!----td class="border" style="padding: 10px;">7</td> -->
              
              <tr  style="border-bottom-style: hidden; border-top-style: hidden;">
                <td class="border" colspan="2">
                  <div>Optimised</div>                    
                </td>
                
                <td :class="getDataColour(this.percent_excess.episode_productivity)">
                <div>
                  <span><div v-html="message"/></span> 
                </div>
                </td>

                <td :class="getDataColour(this.percent_excess.assets)">
                  <span><div v-html="message"/></span>                    
                </td>

                <td :class="getDataColour(this.percent_excess.workforce)">
                <div>
                  <span><div v-html="message"/></span> 
                </div>
                </td>

                <td :class="getDataColour(this.percent_excess.finance_cost_budget)">
                <div>
                  <span><div v-html="message"/></span> 
                </div>
                </td>                                                 
              </tr>
              
            </tbody>
          </table>  
          
         

      

          <div>
            <h1><br /></h1>
          </div>

          <div class="chartcontainer5">
            <div class="title_text5">Episodes</div>
            <Bar @on-receive="updateEpisodes"
              :chart-options="chartOptions"
              :chart-data="dataCollectionEpisodes"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>

          <div class="chartcontainer5">
            <div class="title_text5">Assets</div>
            <Bar
              :chart-options="chartOptions"
              :chart-data="dataCollectionAssets"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>

          <div class="chartcontainer5">
            <div class="title_text5">Workforce</div>
            <Bar
              :chart-options="chartOptions"
              :chart-data="dataCollectionWorkforce"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>

          <div class="chartcontainer5">
            <div class="title_text5">Finance</div>
            <Bar
              :chart-options="chartOptions"
              :chart-data="dataCollectionFinance"
              :chart-id="chartId"
              :dataset-id-key="datasetIdKey"
              :plugins="plugins"
              :css-classes="cssClasses"
              :styles="styles"
              :width="100"
              :height="60"
            />
          </div>

          <div>
            <h1><br /></h1>
          </div>


          <div v-if="Summary == 'Episodes'">         
          <!-- 1 open -->
          <div v-if="stageFive != null">
          <table class="table-primary">
            <thead>
              <tr>                  
                <th class="border px-4 py-2" colspan="2">Episode Productivity</th>
              </tr>
            </thead>
            <tbody>
              <tr>          
                <td class="border">
                  <div v-for="(jobPlan, index) in EpisodeSummary" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlan, index) in EpisodeProductivity" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>  
    
    </div>
    </div>

    <div v-if="Summary == 'Strategic'">         
          <!-- 1 open -->
          <div v-if="stageFive != null">
          <table class="table-primary">
            <thead>
              <tr>                  
                <th class="border px-4 py-2" colspan="1">Strategic Analytic Insights</th>
              </tr>
            </thead>
            <tbody>
              <tr>          
                <td class="border">
                  <div v-for="(jobPlan, index) in StrategicInsight" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>  
    
    </div>
    </div>

    <div v-if="Summary == 'Assets and Infrastructure'">         
          <!-- 1 open -->
          <div v-if="stageFive != null">
          <table class="table-primary">
            <thead>
              <tr>                  
                <th class="border px-4 py-2" colspan="2">Assets and Infrastructure</th>
              </tr>
            </thead>
            <tbody>
              <tr>          
                <td class="border">
                  <div v-for="(jobPlan, index) in AssetsSummary" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlan, index) in AssetsProductivity" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>  
    
    </div>
    </div>

    <div v-if="Summary == 'Workforce'">         
          <!-- 1 open -->
          <div v-if="stageFive != null">
          <table class="table-primary">
            <thead>
              <tr>                  
                <th class="border px-4 py-2" colspan="2">Workforce</th>
              </tr>
            </thead>
            <tbody>
              <tr>          
                <td class="border">
                  <div v-for="(jobPlan, index) in WorkforceSummary" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlan, index) in WorkforceProductivity" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>  
    
    </div>
    </div>

    <div v-if="Summary == 'Finance'">         
          <!-- 1 open -->
          <div v-if="stageFive != null">
          <table class="table-primary">
            <thead>
              <tr>                  
                <th class="border px-4 py-2" colspan="2">Finance</th>
              </tr>
            </thead>
            <tbody>
              <tr>          
                <td class="border">
                  <div v-for="(jobPlan, index) in FinanceSummary" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>

                <td class="border">
                  <div v-for="(jobPlan, index) in FinanceProductivity" :key="index">
                    <span v-if="jobPlan == ''"><div v-html="message"/></span>
                    <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                    <span v-else> {{  jobPlan }}</span> 
                  </div>
                </td>
              </tr>
            </tbody>
          </table>  
    
    </div>
    </div>
          
        
          
        
        </div>
        </div>
        </div>       
    </div>

   

    </div>
</template>
<script>
import { Bar } from "vue-chartjs/legacy";
//import { Bubble } from "vue-chartjs/legacy";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "bar",
  components: {
    Bar,
    //Bubble,
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      stageSummary: "metrics",
      selectedSpeciality: "AHPs",
      apiPayload: { speciality: "", apiString: "" },
      selectedSpecialityArea: "Patient Episodes",
      improvementSpecialityArea: "Diagnostics",
      specialityType: "Patient Episodes",
      improvementType: "Diagnostics",
      specialityApi: "ahpstwo-collection",
      population: "",
      Summary: "Episodes",
      firstUpdate: false,
      current_optimised: { population: 0, episode_productivity: 0, assets: 0, workforce: 0, finance_cost_budget: 0},
      percent_excess: { population: 0, episode_productivity: 0, assets: 0, workforce: 0, finance_cost_budget: 0},
      optimised: { population: 0, episode_productivity: 0, assets: 0, workforce: 0, finance_cost_budget: 0 },
      dataCollectionPopulation: [],
      dataCollectionEpisodes: [],
      dataCollectionAssets: [],
      dataCollectionWorkforce: [],
      dataCollectionFinance: [],
      message: "<BR />",
    };
  },
  updated() {
    if (this.firstUpdate == false) {
      console.log ("updated!!!!");   

      this.dataCollectionEpisodes = {
        labels: this.getBarGraphLabels(1, "episodes"),
        datasets: [
          {
            label: this.getBarGraphDataLabels(0),
            data: this.getBarGraphDataValues2("episodes"),
            //data: [100, 100, 100],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgb(75, 192, 192)"],
            borderWidth: 1,
          },
          {
            label: this.getBarGraphDataLabels(1),
            data: this.getBarGraphDataValues3("episodes"),
            //data: [200, 200, 200],
            backgroundColor: ["rgba(54, 162, 235, 0.2)"],
            borderColor: ["rgb(54, 162, 235)"],
            borderWidth: 1,
          },
        ],
        chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            onClick: this.handleCollectionEpisodes,
        },
      };

      this.dataCollectionAssets = {
        labels: this.getBarGraphLabels(2,"assets"),
        datasets: [
          {
            label: this.getBarGraphDataLabels(0),
            data: this.getBarGraphDataValues2("assets"),
            //data: [100, 100, 100],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgb(75, 192, 192)"],
            borderWidth: 1,
          },
          {
            label: this.getBarGraphDataLabels(1),
            data: this.getBarGraphDataValues3("assets"),
            //data: [200, 200, 200],
            backgroundColor: ["rgba(54, 162, 235, 0.2)"],
            borderColor: ["rgb(54, 162, 235)"],
            borderWidth: 1,
          },
        ],
      };

      this.dataCollectionWorkforce = {
        labels: this.getBarGraphLabels(3,"workforce"),
        datasets: [
          {
            label: this.getBarGraphDataLabels(0),
            data: this.getBarGraphDataValues2("workforce"),
            //data: [100, 100, 100],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgb(75, 192, 192)"],
            borderWidth: 1,
          },
          {
            label: this.getBarGraphDataLabels(1),
            data: this.getBarGraphDataValues3("workforce"),
            //data: [200, 200, 200],
            backgroundColor: ["rgba(54, 162, 235, 0.2)"],
            borderColor: ["rgb(54, 162, 235)"],
            borderWidth: 1,
          },
        ],
      };

      this.dataCollectionFinance = {
        labels: this.getBarGraphLabels(4, "finance"),
        datasets: [
          {
            label: this.getBarGraphDataLabels(0),
            data: this.getBarGraphDataValues2("finance"),
            //data: [100, 100, 100],
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgb(75, 192, 192)"],
            borderWidth: 1,
          },
          {
            label: this.getBarGraphDataLabels(1),
            data: this.getBarGraphDataValues3("finance"),
            //data: [200, 200, 200],
            backgroundColor: ["rgba(54, 162, 235, 0.2)"],
            borderColor: ["rgb(54, 162, 235)"],
            borderWidth: 1,
          },
        ],
      };

      this.firstUpdate = true;
    }
  },
  beforeMount() {
    console.log("before mounted");
    this.getMongoDBData();
  },
  mounted() {
    console.log("mounted");

    this.getMongoDBData();

  },
  computed: {
    //store() {
    //  return this.$store.state.prototypedata;
    //},
    stageFive() 
    {
      if (this.$store.state.prototypedata.stageFive.length > 0) {
        return true;
      }
      return null;
    },  
    

    StageSummary() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      if (this.$store.state.prototypedata.stageFive.length > 0) {
        console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFive[0].stage5summary;
      }
      return null;
    },
    EpisodeSummary() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("episode_productivity", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].episode_productivity;
      }
      return null;
    },
    EpisodeProductivity() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("episode_insight", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].episode_insight;
      }
      return null;
    },
    AssetsSummary() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("assets", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].assets;
      }
      return null;
    },
    StrategicInsight() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("strategic_analytics_insights", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].strategic_analytics_insights;
      }
      return null;
    },
    AssetsProductivity() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("assets_insight", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].assets_insight;
      }
      return null;
    },

    WorkforceSummary() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("workforce", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].workforce;
      }
      return null;
    },
    WorkforceProductivity() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("workforce_insight", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].workforce_insight;
      }
      return null;
    },
    FinanceSummary() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("finance", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].finance;
      }
      return null;
    },
    FinanceProductivity() {
      //console.log (" this.$store.state.prototypedata: ", this.$store.state.prototypedata);

      // stageFiveInsights

      var i = this.getDataIndex ("finance_insight", this.$store.state.prototypedata.stageFiveInsights);

      console.log ("Episode Summary: ", i);

      if (this.$store.state.prototypedata.stageFiveInsights.length > 0) {
        //console.log ("this.$store.state.prototypedata.stage5: ", this.$store.state.prototypedata.stageFive[0].stage5Summary);
        return this.$store.state.prototypedata.stageFiveInsights[i].finance_insight;
      }
      return null;
    },
    StagePopulation() {
      var data = new Array ();
      var str = "";

      if (this.$store.state.prototypedata.stageFive.length > 0) {
        for (var i = 0; i< this.$store.state.prototypedata.stageFive[0].population.length; i++)
        {
          str =  this.$store.state.prototypedata.stageFive[0].population[i];
          //console.log ("str: ", str);
          data.push (this.numberWithCommas (str));

          if (i == 1)
          {
            console.log ("data [0] : ", data[0]);
            console.log ("data [1] : ", data[1]);
            //this.current_optimised.population = this.numberWithCommas(data[0].replaceAll (",","") - data[1].replaceAll (",",""));
            //this.percent_excess.populated = this.numberWithPercent(data[0].replaceAll (",","") / data[1].replaceAll (",",""));

            //var num=25;
            //var s = Number(num/100).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2}); 
            //console.log(s);
          }


        }
    
        return data;
      }
      return null;
    },

    /* eslint-disable */
    StageAssets() {
      var data = new Array ();
      var str = "";

      if (this.$store.state.prototypedata.stageFive.length > 0) {
        for (var i = 0; i< this.$store.state.prototypedata.stageFive[0].assets.length; i++)
        {
          str =  this.$store.state.prototypedata.stageFive[0].assets[i];
          //console.log ("str: ", str);
          data.push (this.numberWithCommas (str));


          if (i == 1)
          {
            console.log ("data [0] : ", data[0]);
            console.log ("data [1] : ", data[1]);
            this.current_optimised.assets = this.numberWithCommas(data[0].replaceAll (",","") - data[i].replaceAll (",",""));
            this.percent_excess.assets = this.numberWithPercent(data[0].replaceAll (",","") / data[i].replaceAll (",","")).replaceAll ("%","");
          }
        }
    
        return data;
      }
      return null;
    },
    StageEpisodeProductivity() 
    {
      var data = new Array();
      var str ="";


      if (this.$store.state.prototypedata.stageFive.length > 0) {
        for (var i = 0; i< this.$store.state.prototypedata.stageFive[0].episode_productivity.length; i++)
        {
          str =  this.$store.state.prototypedata.stageFive[0].episode_productivity[i];
          //console.log ("str: ", str);
          data.push (this.numberWithCommas (str));

          if (i == 1)
          {
            console.log ("data [0] : ", data[0]);
            console.log ("data [1] : ", data[1]);
            this.current_optimised.episode_productivity = this.numberWithCommas(data[0].replaceAll (",","") - data[1].replaceAll (",",""));
            this.percent_excess.episode_productivity = this.numberWithPercent(data[0].replaceAll (",","") / data[1].replaceAll (",","")).replaceAll ("%","");
          }
        }
        return data;
      }
      return null;
    },
    StageWorkforce() {
      var data = new Array();
      var str = "";

      if (this.$store.state.prototypedata.stageFive.length > 0) {
        for (var i = 0; i< this.$store.state.prototypedata.stageFive[0].workforce.length; i++)
        {
          str =  this.$store.state.prototypedata.stageFive[0].workforce[i];
          //console.log ("str: ", str);
          data.push (this.numberWithCommas (str));

          if (i == 1)
          {
            console.log ("data [0] : ", data[0]);
            console.log ("data [1] : ", data[1]);
            this.current_optimised.workforce = this.numberWithCommas (data[0].replaceAll (",","") - data[1].replaceAll (",",""));
            this.percent_excess.workforce = this.numberWithPercent(data[0].replaceAll (",","") / data[1].replaceAll (",","")).replaceAll ("%","");
          }
        }
        return data;            
      }
      return null;
    },
    StageFinance() {
      var data = new Array();
      var str = "";

      if (this.$store.state.prototypedata.stageFive.length > 0) {
        for (var i = 0; i< this.$store.state.prototypedata.stageFive[0].finance_cost_budget.length; i++)
        {
          str =  this.$store.state.prototypedata.stageFive[0].finance_cost_budget[i];
          //console.log ("str: ", str);
          data.push (this.numberWithCommas (str));

          if (i == 1)
          {
            console.log ("data [0] : ", data[0]);
            console.log ("data [1] : ", data[1]);
            this.current_optimised.finance_cost_budget = this.numberWithCommas(data[0].replaceAll (",","") - data[1].replaceAll (",",""));
            this.percent_excess.finance_cost_budget = this.numberWithPercent(data[0].replaceAll (",","") / data[1].replaceAll (",","")).replaceAll ("%","");
          }
        }
        return data;            
      }
      return null;    
    },
    
   
  },
  /* eslint-enable */
  methods: {
    getDataColour (value)
    {
      var colourString = "";

      if (value < 10.00)
      {
        colourString = "green-colour"; // red
      }
      else if (value > 10.01 && value < 20.01)
      {
        colourString = "amber-colour";
      }
      else if (value > 20.01)
      {
        colourString = "red-colour";
      }

      console.log ("colourString: ", colourString)

      return colourString;

    },
    getDataIndex(itemToSearch, dataStruct) {
      var i = 0;
      var index = 0;

      for (
        i = 0;
        i < dataStruct.length;
        i++
      ) {
        if (
          itemToSearch in
          dataStruct[i]
        ) {
          index = i;
        }
      }

      return index;
    },
    //handleCollectionEpisodes(point, event)
    //{
//      console.log ("handleCollectionEpisodes");
    //},
    getMongoDBData() 
    {
      this.$store.dispatch("getBDFStageFiveSummaries").then((data) => {
        console.log("data", data);
      });

      this.$store.dispatch("getBDFStageFiveInsights").then((data) => {
        console.log("data", data);
      });
    },
    editSummary(Summary) {
      this.Summary = Summary;
    },

  

    getBarGraphDataValues2(str) {
      var data = new Array();
      var newstr = "";

      //console.log("getBarGraphValues2");
      //console.log ("==> ", this.$store.state.prototypedata.stageFive[0].workforce);

      if (str == "workforce") 
      {
        newstr = this.$store.state.prototypedata.stageFive[0].workforce[0].replaceAll(",", "");
        data.push(newstr);
      }
      
       else if (str == "assets") { 
        newstr = this.$store.state.prototypedata.stageFive[0].assets[0].replaceAll(",", "");
        data.push(newstr);
      } else if (str == "finance") {
        newstr = this.$store.state.prototypedata.stageFive[0].finance_cost_budget[0].replaceAll(",", "");
        data.push(newstr);
      } else if (str == "episodes") {
        newstr = this.$store.state.prototypedata.stageFive[0].episode_productivity[0].replaceAll(",", "");
        data.push(newstr);
      }

      console.log ("getBarGraphDataValues2: ", newstr);

      return data;
    },
    getBarGraphDataValues3(str) {
      var data = new Array();
      var newstr = "";

      //console.log("getBarGraphValues1");

      if (str == "workforce") 
      {
        newstr = this.$store.state.prototypedata.stageFive[0].workforce[1].replaceAll(",", "");
        data.push(newstr);
      }
       else if (str == "assets") {
        newstr = this.$store.state.prototypedata.stageFive[0].assets[1].replaceAll(",", "");
        data.push(newstr);
      } else if (str == "finance") {
        newstr = this.$store.state.prototypedata.stageFive[0].finance_cost_budget[1].replaceAll(",", "");
        data.push(newstr);
      } else if (str == "episodes") {
        newstr = this.$store.state.prototypedata.stageFive[0].episode_productivity[1].replaceAll(",", "");
        data.push(newstr);
      }
      
      console.log ("getBarGraphDataValues3: ", newstr);
      return data;
    },
    getBarGraphLabels(index, str) {
      var data = new Array();
      console.log("getBarGraphLabels", index);      

      if (str == "workforce") 
      {
        data.push("Workforce");
      } else if (str == "assets") 
      {
        data.push("Assets");
      } 
      else if (str == "population") {
        data.push("Population");
      }
      else if (str == "finance") 
      {
        data.push("Finance");
      } else if (str == "episodes") 
      {
        data.push("Episode Productivity");
      }

      return data;
    },

    getBarGraphDataLabels(index)
    {
      var data = new Array();
      

      if (index == 0) 
      {
        data.push(this.$store.state.prototypedata.stageFive[0].stage5summary[0]);
      } else 
      {
        data.push(this.$store.state.prototypedata.stageFive[0].stage5summary[1]);
      } 

      return data;
    },
      
/*      if (this.isEmpty(temp) == false || typeof temp !== "undefined") {
        console.log("temp", temp);
        for (var i = 0; i < temp.length; i++) {
          str = temp[i].replaceAll(",", "");
          data.push(str);
        }
      }

      console.log("getBarGraphDataValues:", data);

      return data;
    },*/
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    numberWithPercent(x)
    {
      return  Number(x).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2}); 
    },
    calculateArray(data) {
      let arrayValuesFloatArray = new Array();
      let str = "";
      let parse = false;

      if (typeof data != "undefined") {
        //tempValue = 0;
        str = "";
        parse = false;

        for (var i = 0; i < data.length; i++) {
          //console.log("data[i] = ", data[i]);
          str = String(data[i]);
          str = str.replaceAll(",", "");
          //str = str.replaceAll(",", ".");

          //str = str.replaceAll(".", ",");

          if (str.includes(".")) {
            parse = true;
          }

          //console.log("str[i] = ", str);

          arrayValuesFloatArray.push(parseFloat(str));

          //console.log("arrayValuesFloatArray[i]=", arrayValuesFloatArray[i]);

          //tempValue = tempValue + str;
        }
      }

      //console.log("tempValue = ", tempValue);

      const total = arrayValuesFloatArray.reduce(function (a, b) {
        return a + b;
      });
      if (parse == true) {
        return total.toFixed(2);
      } else {
        return total;
      }
    },


 
  },
};
</script>
<style>
.red-colour {
  background-color:  #FF0000;
}

.green-colour {
  background-color: #008000;
}

.amber-colour {
  background-color: #ffbf00;
}

.chartcontainer5 {
  border: 1px solid;
  border-color: #e5e9f2;
  height: 300px;
  display: inline-block;
  width: 350px; /* was 26 rem */
  margin-right: 20px;
  padding-bottom: 5px;
}
.title_text5 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
</style>
